import React, { useState } from "react";
import { toast } from "react-toastify";

import api from "../../../service/api";
import Icone from "../../../components/Icone";
import { isEmpty } from "../../../components/Util";
import { BotaoDeletar, BotaoSalvar, BotaoVoltar } from "../../../components/Botao";

const DoseTrabalhista = ({ matematica, setor, formaRisco, carregarAmbientes }) => {
  const [novaDoseTrabalhista, setNovaDoseTrabalhista] = useState(matematica?.resultado_avaliacao_probabilidade);
  const [carregando, setCarregando] = useState(false);

  async function Salvar(matematica, novaDoseTrabalhista) {
    if (novaDoseTrabalhista < 0 || novaDoseTrabalhista > 100) {
      toast.warning("O número digitado não é aceito para cadastro!");
    } else {
      setCarregando(true);
      await api
        .put(`/gro/pgr/matematica/Matematica/inserirDoseTrabalhista/${matematica.id}`, {
          resultado_avaliacao_probabilidade: novaDoseTrabalhista,
        })
        .then(() => {
          carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id);
          setCarregando(false);
        });
    }
  }

  async function Deletar(matematica) {
    await api.put(`/gro/pgr/matematica/Matematica/removerDoseTrabalhista/${matematica.id}`).then(() => {
      carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id);
      document.getElementById("dose-trabalhista").value = "";
      setCarregando(false);
    });
  }

  return (
    matematica?.isDoseTrabalhista === "true" && (
      <>
        <div className="d-flex align-items-center mb-3">
          <Icone className="text-primary ml-3" icone="exclamation-circle" />
          <span className="mr-3 ml-1">% Dose (trabalhista)</span>
          <div className="input-group w-25">
            <input
              id="dose-trabalhista"
              type="text"
              className="form-control"
              placeholder={isEmpty(novaDoseTrabalhista) && "%LT"}
              onChange={(e) => setNovaDoseTrabalhista(e.target.value)}
              defaultValue={matematica?.resultado_avaliacao_probabilidade}
            />

            {novaDoseTrabalhista === matematica?.resultado_avaliacao_probabilidade ? (
              <>
                {isEmpty(matematica?.resultado_avaliacao_probabilidade) ? (
                  <>
                    <BotaoSalvar
                      isLoading={carregando}
                      disabled={isEmpty(novaDoseTrabalhista)}
                      onClick={() => {
                        Salvar(matematica, novaDoseTrabalhista);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <BotaoDeletar
                      isLoading={carregando}
                      disabled={isEmpty(matematica?.resultado_avaliacao_probabilidade)}
                      onClick={() => {
                        Deletar(matematica);
                        setCarregando(true);
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <BotaoVoltar
                  onClick={() => {
                    setNovaDoseTrabalhista(matematica?.resultado_avaliacao_probabilidade)
                    document.getElementById("dose-trabalhista").value = matematica?.resultado_avaliacao_probabilidade;
                  }}
                />
                <BotaoSalvar
                  isLoading={carregando}
                  disabled={isEmpty(novaDoseTrabalhista) || novaDoseTrabalhista === matematica?.resultado_avaliacao_probabilidade}
                  onClick={() => {
                    Salvar(matematica, novaDoseTrabalhista);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default DoseTrabalhista;